import {
  FC,
  HTMLProps,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
} from 'react';

import ExclamationCircleIcon from '@heroicons/react/20/solid/ExclamationCircleIcon';
import cn from 'classnames';
import { FieldError } from 'react-hook-form';

import HelpLinkComponent from './help-link.component';

type Variant = 'primary' | 'messenger';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
  variant?: Variant;
  label?: ReactNode | string;
  helpLink?: string;
  icon?: ReactNode;
  helpText?: ReactNode | string;
  error?: FieldError;
  trailingAddOn?: string;
  prefix?: string;
  maxValueLabel?: string;
}

const Input: FC<InputProps & Pick<HTMLProps<HTMLInputElement>, 'ref'>> =
  forwardRef<HTMLInputElement, InputProps>(
    (
      {
        variant = 'primary',
        maxValueLabel,
        label,
        icon,
        helpText,
        error,
        trailingAddOn,
        className,
        helpLink,
        disabled,
        inputClassName,
        prefix,
        ...rest
      },
      ref,
    ) => {
      return (
        <div data-testid="input-wrapper" className={cn(className)}>
          {label && (
            <label
              htmlFor={rest?.id}
              className="mb-2 flex items-center text-sm font-medium leading-6 text-gray-900"
              data-testid="input-label"
            >
              {label}
              {helpLink ? <HelpLinkComponent data-testid="help-link" helpLink={helpLink} /> : null}
            </label>
          )}

          <div
            data-testid="input-relative-wrapper"
            className={cn('relative', {
              ['rounded-md shadow-sm']: variant === 'primary',
            })}
          >
            <div
              data-testid="input-container"
              className={cn('relative', {
                ['flex items-center rounded-md outline outline-2 outline-transparent']:
                  variant === 'primary',
                ['focus-within:outline-main']: variant === 'primary' && !error,
                ['focus-within:outline-red-500']:
                  variant === 'primary' && !!error,
              })}
            >
              {maxValueLabel && (
                <div
                  data-testid="input-max-value-label"
                  className="absolute -top-5 right-0 text-xs font-medium text-gray-500"
                >
                  {maxValueLabel}
                </div>
              )}
              {prefix && (
                <div
                  data-testid="input-prefix"
                  className={cn(
                    'h-full rounded-[6px_0px_0px_6px] border bg-gray-50 px-3 py-2 text-sm font-normal',
                    {
                      ['border-red-300 text-red-500']: !!error,
                      ['border-gray-300 text-gray-500']: !error,
                    },
                  )}
                >
                  {prefix}
                </div>
              )}

              {icon && (
                <div
                  data-testid="input-icon"
                  className={cn(
                    'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3',
                    {
                      ['text-red-900']: !!error,
                      ['text-gray-400']: !error,
                    },
                  )}
                >
                  {icon}
                </div>
              )}

              <input
                data-testid="input"
                className={cn(
                  variant === 'primary'
                    ? {
                        ['block w-full py-2 ring-0 focus:!border-transparent focus:!shadow-none focus:!outline-transparent focus:ring-0 focus-visible:outline-none sm:text-sm']:
                          true,
                        ['pl-10']: !!icon,
                        ['pl-3']: !icon,
                        ['rounded-[0px_6px_6px_0px] border-b border-l-0 border-r border-t']:
                          !!prefix,
                        ['rounded-md border']: !prefix,
                        ['border-red-300 pr-10 text-red-900 placeholder:text-red-300']:
                          !!error,
                        ['border-gray-300 pr-3 text-gray-900 placeholder:text-gray-500']:
                          !error,
                        ['cursor-not-allowed opacity-50']: disabled,
                      }
                    : {
                        ['m-0 w-full flex-1 resize-none appearance-none border-0 bg-inherit p-0 text-base leading-5 placeholder-[#999999] focus:outline-none focus:ring-0 focus-visible:ring-0 md:text-sm']:
                          true,
                        ['cursor-not-allowed opacity-50']: disabled,
                      },
                  inputClassName,
                )}
                onChange={(e) => {
                  rest.onChange && rest.onChange(e);
                }}
                ref={ref}
                disabled={disabled}
                {...rest}
              />

              {error && (
                <div
                  data-testid="input-error-icon"
                  className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {trailingAddOn && (
              <div
                data-testid="input-trailing-addon"
                className="pointer-events-none absolute inset-y-0 right-6 flex items-center pr-3"
              >
                <span className="text-gray-500 sm:text-sm">
                  {trailingAddOn}
                </span>
              </div>
            )}
          </div>

          {error && (
            <p
              data-testid="input-error-message"
              className="mt-2 text-sm text-red-600"
              id="email-error"
            >
              {error.message}
            </p>
          )}
          {helpText && (
            <div
              data-testid="input-help-text"
              className="mt-2 text-xs font-normal leading-4 text-gray-500"
            >
              {helpText}
            </div>
          )}
        </div>
      );
    },
  );

Input.displayName = 'Input';

export default Input;
