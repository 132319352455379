import { isEqual } from 'lodash';

const safeJsonParse = <T>(str: string) => {
  try {
    const jsonValue: T = JSON.parse(str);

    return jsonValue;
  } catch {
    return undefined;
  }
};

function getChangedKeysAndValues<T extends object>(
  defaultObj: T,
  newObj: Partial<T>,
): { key: string; newValue: any }[] {
  const changedItems: { key: string; newValue: any }[] = [];

  function compareObjects(
    defaultObj: any,
    newObj: any,
    prefix: string = '',
  ): void {
    Object.entries(newObj).forEach(([key, newValue]) => {
      const newPrefix = prefix ? `${prefix}.${key}` : key;
      const defaultValue = defaultObj[key];

      if (isObject(newValue) && isObject(defaultValue)) {
        compareObjects(defaultValue, newValue, newPrefix);
      } else if (!isEqual(newValue, defaultValue)) {
        changedItems.push({ key: newPrefix, newValue });
      }
    });
  }

  compareObjects(defaultObj, newObj);
  return changedItems;
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

export { safeJsonParse, getChangedKeysAndValues };
